import consumer from "./consumer"

consumer.subscriptions.create("MessageNotificationsChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        // Called when there's incoming data on the websocket for this channel
        Notification.requestPermission(permission=>{
            const notification = new Notification(data['name'], {body: data['message'], icon: '/assets/logo_favicon_yellow'});
        });
    }
});
