import consumer from "./consumer"

document.addEventListener("turbolinks:load", () => {

  const data = document.getElementById("data")
  if (data == null) {
    return
  }
  const channel = "RoomChannel"
  const room_id = data.getAttribute("data-room-id")
  const user_id = data.getAttribute("data-user-id")
  const adviser_id = data.getAttribute("data-adviser-id")
  const kind_of_user = data.getAttribute("data-kind-of-user")
  consumer.subscriptions.create(
    { channel: "RoomChannel", room_id: room_id, user_id: user_id, adviser_id, kind_of_user: kind_of_user },
    {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
       const container = document.getElementById("container")
       container.insertAdjacentHTML('beforeend', data['message'])
       document.getElementById('scroll-inner').scrollTop = document.getElementById('scroll-inner').scrollHeight
    },
  })
})
