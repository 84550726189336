// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require activestorage
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
//= require swiper/swiper-bundle.js
//= require swiper.js
import App from '../app.vue'

require('jquery')
import "cocoon"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// $(".hamburger-menu-icon").click(function () {
//     $(".hamburger-menu-icon__line").toggleClass('active');
// })
$(document).on('click', '.hamburger-menu-icon', function () {
    $(".hamburger-menu-icon__line").toggleClass('active');
    $(".header-ul").toggleClass('header-ul-open');
})